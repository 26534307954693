import React from 'react'
import classes from "./Trading.module.scss"
import img1 from "../../../assets/img/main/3/1.png"
import {useTranslation, Trans} from "react-i18next";
const Trading = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.trading, "mt"].join(" ")}>
            <div className={[classes.tradingBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.tradingMobBlock, ""].join(" ")}>
                        <h2 className={[classes.tradingMobBlockTitle, "font-35"].join(" ")}>
                            {t("home_43")}
                        </h2>
                        <p className={[classes.tradingMobBlockText, "font-17"].join(" ")}>
                            <Trans>home_44</Trans>
                        </p>
                    </div>
                    <div className={[classes.tradingRow, ""].join(" ")}>
                        <div className={[classes.tradingRowLeft, ""].join(" ")}>
                            <p className={[classes.tradingRowLeftOverTitle, "font-50"].join(" ")}>
                                {t("home_45")}
                            </p>
                            <h2 className={[classes.tradingRowLeftTitle, "font-120"].join(" ")}>
                                {t("home_46")}
                            </h2>
                            <p className={[classes.tradingRowLeftText, "font-17"].join(" ")}>
                                {t("home_47")}
                            </p>
                        </div>


                        <div className={[classes.tradingRowRight, ""].join(" ")}>
                            <div className={[classes.tradingRowRightImg, ""].join(" ")}>
                                <img src={img1} alt="Why Clients Will Love Us..." />
                            </div>
                            <p className={[classes.tradingRowRightOverTitle, "font-50"].join(" ")}>
                                {t("home_48")}
                            </p>
                            <h2 className={[classes.tradingRowRightTitle, "font-120"].join(" ")}>
                                {t("home_49")}
                            </h2>
                            <p className={[classes.tradingRowRightSubTitle, "font-30"].join(" ")}>
                                {t("home_50")}
                            </p>
                            <p className={[classes.tradingRowRightText, "font-17"].join(" ")}>
                                {t("home_51")}
                            </p>
                            <div className={[classes.tradingRowRightBtn, ""].join(" ")}>
                                <a href="https://user.avos-finance.tech/login">
                                    <svg width="61" height="45" viewBox="0 0 61 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="6.10352e-05" width="61" height="45" rx="20" fill="#fff" />
                                        <path d="M29.4683 31.2155L36.2359 24.3622C36.4781 24.1174 36.6703 23.8266 36.8014 23.5065C36.9326 23.1864 37.0001 22.8433 37.0001 22.4967C37.0001 22.1502 36.9326 21.807 36.8014 21.4869C36.6703 21.1668 36.4781 20.8761 36.2359 20.6313L29.4683 13.778C27.8221 12.111 25.0001 13.3017 25.0001 15.6567L25.0001 29.3633C25.0001 31.7183 27.8221 32.8825 29.4683 31.2155Z" fill="black" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trading
